import Pagination from '@/components/Pagination/Pagination.vue'
import GraphQlMixin from '@/mixins/graphql.mixin'
import QueryMixin from '@/mixins/query.mixin'

export default {
  props: ['updatedItem'],
  mixins: [GraphQlMixin, QueryMixin],
  components: {
    Pagination
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: 'Settings',
          to: { name: 'settings' },
          exact: true
        },
        {
          text: 'Discovery',
          disabled: true
        }
      ],
      asset: {},
      devices: [],
      devicesCount: 0,
      footerProps: { 'items-per-page-options': [10, 25, 50, 100, 200] },
      options: {
        page: 1,
        itemsPerPage: 25,
        sortBy: [],
        sortDesc: [],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: true
      },
      headers: [
        { text: 'In RAMS', value: 'ramsId', sortable: false },
        {
          text: '45 Days No Report',
          value: 'fortyFiveDaysNoReport',
          sortable: false
        },
        { text: 'Crowdstrike ID', value: 'crowdstrikeId' },
        { text: 'Host Name', value: 'hostname' },
        { text: 'Serial Number', value: 'serialNumber' },
        { text: 'Ou', value: 'ou' },
        { text: 'Last Seen', value: 'lastSeen' },
        { text: 'Modified Timestamp', value: 'modifiedTimestamp' },
        { text: 'First Seen', value: 'firstSeen' },
        { text: 'Username*', value: 'username' },
        { text: 'Encryption Status*', value: 'encryptionStatus' },
        { text: 'System Product Name', value: 'systemProductName' },
        { text: 'System Manufacturer', value: 'systemManufacturer' },
        { text: 'OS Version', value: 'osVersion' },
        { text: 'Local IP', value: 'localIp' },
        { text: 'MAC Address', value: 'macAddress' }
      ],
      search: '',
      debouncing: false,
      debounceTimeout: null
    }
  },
  computed: {
    totalPage() {
      return this.options.itemsPerPage === -1 || this.devicesCount === 0
        ? 1
        : Math.ceil(this.devicesCount / this.options.itemsPerPage)
    }
  },
  watch: {
    options: {
      handler() {
        this.$apollo.queries.crowdstrikeComputers.refetch()
      },
      deep: true
    },
    search() {
      this.$apollo.queries.crowdstrikeComputers.refetch()
    },
    redirect() {}
  },
  apollo: {
    crowdstrikeComputers: {
      query() {
        const fields = this.getFieldsGql('read', 'CrowdstrikeComputer', [
          'ramsId',
          'crowdstrikeId',
          'hostname',
          'ou',
          'serialNumber',
          'encryptionStatus',
          'systemProductName',
          'systemManufacturer',
          'firstSeen',
          'lastSeen',
          'modifiedTimestamp',
          'osVersion',
          'localIp',
          'macAddress',
          'username',
          'fortyFiveDaysNoReport'
        ])
        return this.$gql`
          query SearchCrowdstrikeComputers(
            $q: String
            $take: Int
            $skip: Int
            $orderBy: [OrderBy]
          ) {
            crowdstrikeComputers(search: $q, take: $take, skip: $skip, orderBy: $orderBy) {
              items {
                ${fields}
              }
              totalCount
            }
          }
        `
      },
      variables() {
        return {
          q: this.search,
          take:
            this.options.itemsPerPage === -1 ? null : this.options.itemsPerPage,
          skip:
            this.options.itemsPerPage === -1
              ? 0
              : this.options.itemsPerPage * (this.options.page - 1),
          orderBy: this.getOrderBy()
        }
      },
      skip() {
        return (
          !_.get(this, 'options.page') ||
          this.debouncing ||
          this.loadingQuery ||
          !this.ability.can('read', 'CrowdstrikeComputer')
        )
      },
      result({ data, error }) {
        if (data && data.crowdstrikeComputers) {
          if (this.updatedItem !== undefined) {
            for (let i = 0; i < data.crowdstrikeComputers.items.length; i++) {
              if (
                data.crowdstrikeComputers.items[i].id === this.updatedItem.id
              ) {
                data.crowdstrikeComputers.items[i] = this.updatedItem
                break
              }
            }
          }
          this.devices = data.crowdstrikeComputers.items
          this.devicesCount = data.crowdstrikeComputers.totalCount
        } else if (error) {
          this.graphQLOnError(
            `Failed to get list of Crowdstrike devices. ${error.toString()}`
          )
        }
      }
    }
  },
  methods: {
    getRegion(ou) {
      if (ou !== null) {
        if (ou.includes('Americas')) {
          return 'Americas'
        } else if (ou.includes('Asia')) {
          return 'Asia Pacific'
        } else if (ou.includes('Europe')) {
          return 'Europe'
        } else if (ou.includes('Japan')) {
          return 'Japan'
        } else {
          return null
        }
      } else {
        return null
      }
    },
    registerDeviceToRams(device) {
      this.asset = {
        name: device.hostname ? device.hostname.trim() : null,
        status: null,
        serialNumber:
          device.serialNumber && device.serialNumber !== 'UNKNOWN'
            ? device.serialNumber.trim()
            : null,
        purchaseCost: null,
        purchaseCurrencyCode: null,
        purchaseDate: null,
        warrantyExpires: null,
        // locationId: null,
        assigneeId: null,
        memo: null,
        costCode: null,
        extraFields: []
      }
      let region = this.getRegion(device.ou)
      if (region) {
        this.asset.region.text(region)
      }
      this.$apollo
        .query({
          // Query
          query: this.$gql`
              query GetModel($name: [String]) {
                models(where: [{ path: "modelNumber", value: $name }]) {
                  items {
                    id
                    name
                    assetType
                    manufacturer {
                      id
                      name
                    }
                  }
                }
              }
            `,
          variables: { name: device.systemProductName }
        })
        .then(({ data }) => {
          if (data.models.items.length > 0) {
            this.asset.model = data.models.items[0]
            this.asset.manufacturer = data.models.items[0].manufacturer
          }
          this.$apollo
            .query({
              query: this.$gql`
                query GetAssignee($samAccountName: [String]) {
                  intraAccounts(where: [{ path: "samAccountName", value: $samAccountName }, { path: "deletedExternally", value: "false" }]) {
                    items {
                      id
                      name
                    }
                  }
                }
              `,
              variables: { samAccountName: device.username }
            })
            .then(({ data }) => {
              if (data.intraAccounts.items.length > 0) {
                this.asset.assignee = data.intraAccounts.items[0]
              }
              this.$router.push({
                name: 'create-asset',
                params: { type: 'computers', discoveryDevice: this.asset }
              })
            })
        })
    }
  },
  created() {
    if (!this.ability.can('read', 'CrowdstrikeComputer')) {
      this.$router.push('/')
    }
  }
}
