var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"crowdstrike-devices-table",attrs:{"column":"","fill-height":""}},[_c('v-breadcrumbs',{staticClass:"pa-2",attrs:{"items":_vm.breadcrumbItems,"divider":">"}}),_c('v-card',{attrs:{"color":"transparent","elevation":"0"}},[_c('v-card-title',{staticClass:"row wrap px-0 pt-1"},[_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',[_c('span',{staticClass:"display-1 font-weight-thin"},[_vm._v("Crowdstrike Devices")])]),_c('v-spacer'),_c('v-text-field',{staticClass:"pr-2",attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},on:{"paste":function($event){$event.preventDefault();return _vm.searchOnPaste.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.devices,"footer-props":_vm.footerProps,"loading":_vm.$apollo.loading,"options":_vm.options,"server-items-length":_vm.devicesCount,"multi-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.ramsId",fn:function(ref){
var item = ref.item;
return [(_vm.ability.can('create', 'Computer'))?[(item.ramsId)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"success","to":("/assets/computers/" + (item.ramsId))}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("View Device on RAMS")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"error"},on:{"click":function($event){return _vm.registerDeviceToRams(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-plus-circle")])],1)]}}],null,true)},[_c('span',[_vm._v("Register Device to RAMS")])])]:_vm._e()]}},{key:"item.fortyFiveDaysNoReport",fn:function(ref){
var item = ref.item;
return [(item.fortyFiveDaysNoReport)?_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v("mdi-circle-outline")]):_vm._e()]}}])}),[_c('v-footer',{staticClass:"transparent",attrs:{"flat":"","padless":""}},[_c('v-card',{staticClass:"flex text-caption transparent py-2",attrs:{"flat":"","tile":""}},[_vm._v(" * Obtained from TEM ")])],1)],_c('pagination',{attrs:{"options":_vm.options,"totalPage":_vm.totalPage}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }